import { useRef, useState } from 'react';
import {
    Avatar,
    Box,
    IconButton,
    Popper,
    Paper,
    useMediaQuery,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItem,
    ListItemText
} from '@mui/material';
import { GlobalOutlined, FlagOutlined } from '@ant-design/icons';
import Transitions from 'components/@extended/Transitions';
import { useTheme } from '@mui/material/styles';
import i18n from 'i18n/config';

const Language = () => {
    const iconBackColorOpen = 'grey.300';
    const iconBackColor = 'grey.100';
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const anchorRef = useRef(null);
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleLanguageClick = (event) => {
        i18n.changeLanguage(event);
        setOpen(false);
    };

    return (
        <Box sx={{ flexShrink: 0, m1: 0.75 }}>
            <IconButton
                disableRipple
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <GlobalOutlined />
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 120,
                                maxWidth: 230,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav">
                                    <ListItemButton onClick={(e) => handleLanguageClick('tr')}>
                                        <ListItemText>TR - Turkish</ListItemText>
                                    </ListItemButton>
                                    <ListItemButton onClick={(e) => handleLanguageClick('en')}>
                                        <ListItemText>EN - English</ListItemText>
                                    </ListItemButton>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Language;
